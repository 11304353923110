import React, { FunctionComponent, useReducer } from 'react';

export const ExploreContext = React.createContext<any>(null);
export const ExploreProvider = ExploreContext.Provider;
export const ExploreConsumer = ExploreContext.Consumer;
export const defaultExploreState = {
    // explore page configs
    exploreControls: true,
    mobileNotice: true,

    // default scroll component state
    scrollState: {
        isMounted: false,
        scrollThreshold: 5,
        currentModuleIndex: 0,
    },

    // components to be used
    exploreComponents: null,
};

interface ExploreContextWrapperProps {
    children: any;
}
export const ExploreContextWrapper: FunctionComponent<ExploreContextWrapperProps> = (
    props: ExploreContextWrapperProps
) => {
    const [state, setState] = useReducer(
        (state: any, newState: any) => ({ ...state, ...newState }),
        defaultExploreState
    );

    return (
        <ExploreProvider
            value={{
                setState,
                state,
            }}
        >
            {props.children}
        </ExploreProvider>
    );
};
