import firebase from './firebase';
import { User } from '../types/User';
import { env } from './utils';

const projectId = process.env.REACT_APP_SANITY_PROJECT_ID as string;

const firestore = firebase.firestore();

// Doesn't use the util env because NODE_ENV tells us whether it is local or not whereas util env tells us more about the stage
if (process.env.NODE_ENV !== 'production') {
    firestore.settings({
        host: `${window.location.hostname || 'localhost'}:8080`,
        ssl: false,
    });
}

export const makeUserPath = (uid: string): string =>
    `/${env}/userData/users/${uid}`;

export const instantiateUser = async (uid: string): Promise<User> => {
    const path = makeUserPath(uid);

    const newData: User = {
        favorites: {
            [projectId]: [],
        },
        firstName: null,
        lastName: null,
    };
    await firestore.doc(path).set(newData);

    await Promise.all([
        firestore.doc(`${path}/subscribed/general`).set({
            subscribed: true,
        }),
        firestore.doc(`${path}/subscribed/${projectId}`).set({
            subscribed: true,
        }),
    ]);

    return newData;
};

export default firestore;
