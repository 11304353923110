import React, { FunctionComponent } from 'react';

interface LoaderProps {
    children?: any;
}
export const Loader: FunctionComponent<LoaderProps> = ({
    children,
}: LoaderProps) => {
    return (
        <div className='Loader'>
            <div className='LoaderAnimationWrapper'>
                <div className='LoaderAnimation'></div>
            </div>
            {children}
        </div>
    );
};
