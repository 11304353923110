// Modules
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
    ReactElement,
    Suspense,
    useMemo,
} from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { FullScreen } from 'react-full-screen';
import { lazily } from 'react-lazily';

// import { ResponsiveImage } from './ResponsiveImage';
import { Loader } from './Loader';
// import { LineAnimation } from './LineAnimation';
// import { HTMLVideo } from './HTMLVideo';
// import { IntroMessage } from './IntroMessage';

// import { ActionMenu } from './ActionMenu';
// import { Explore } from './Explore';
// import { MapComponent } from './Map';

// import headphonesIcon from '../assets/icons/headphones.svg';
// import arrowDownIcon from '../assets/icons/arrow-down.svg';

import { Location } from '../types/Location';

import { AuthContext } from '../contexts/AuthContext';
import { DataContext } from '../contexts/DataContext';
import { ExperienceContext } from '../contexts/ExperienceContext';
import { MessageContext } from '../contexts/MessageContext';
import { ModalContext } from '../contexts/ModalContext';

// Utils
import ReactGA from '../utils/ga';
import { isMobile, isSafari, checkPortrait } from '../utils/utils';

// SASS
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/base.sass';
// import { EmailContext } from '../contexts/EmailContext';

// Lazy
const { ActionMenu } = lazily(() => import('./ActionMenu'));
const { Explore } = lazily(() => import('./Explore'));
const { MapComponent } = lazily(() => import('./Map'));
const { IntroMessage } = lazily(
    () =>
        new Promise((resolve) => {
            setTimeout(() => resolve(import('./IntroMessage')), 3000); // A delay on loading the IntroMessage (currently also delays ActionMenu)
        })
);

ReactGA.pageview(window.location.pathname + window.location.search);

const imageCache: { [key: string]: any } = {};
function preloadImage(src: string) {
    if (imageCache[src]) return imageCache[src];
    if (src) {
        const image = new Image();
        image.src = src;
        imageCache[src] = image;
        return image;
    }
    return null;
}

export function App() {
    const { isExact, path } = useRouteMatch();
    const location = useLocation();
    const { authenticate, signInAnon, user } = useContext(AuthContext);
    const {
        fetchLocations,
        fetchFavorites,
        journey,
        locations,
        userData,
        assetsLoaded,
    } = useContext(DataContext);
    const { filteredLocations, fullScreenHandler, topFrame } = useContext(
        ExperienceContext
    );
    const { message, hideMessage } = useContext(MessageContext);
    const { modal } = useContext(ModalContext);

    const query = new URLSearchParams(location.search);
    const skip = query.get('emailLink');
    const portrait = checkPortrait();

    // const [introImageLoaded, setIntroImageLoaded] = useState<boolean>(false);
    const [videoReady, setVideoReady] = useState<boolean>(false);
    const [introReady, setIntroReady] = useState<boolean>(false);

    // Must be
    // Not iframe
    // Exact pathname match (root)
    // No search params
    // No skip params
    const [intro, setIntro] = useState<boolean>(
        !topFrame && isExact && !location.search && !skip
    );

    const introContentRef = useRef<HTMLDivElement>(null);

    const onScroll = useCallback(
        (e: Event) => {
            if (e && e.target && introReady) {
                const scrollPos = (e.target as HTMLDivElement).scrollTop;
                const tolerance = 300; // px until the intro screen goes away.
                const opacityTotalChange = 0.5;
                const translateYTotalChange = 100;

                // Animate the intro content while scrolling.
                if (introContentRef && introContentRef.current) {
                    const opacityPerPx = opacityTotalChange / tolerance; // Total opacity change 0.5
                    const translateYPerPx = translateYTotalChange / tolerance; // Total translateY change 25px;

                    (e.target as HTMLElement).style.opacity = `${
                        1 - opacityPerPx * scrollPos
                    }`;
                    // introContentRef.current.style.opacity = `${1 - (opacityPerPx * scrollPos)}`;
                    introContentRef.current.style.transform = `translateY(-${
                        translateYPerPx * scrollPos
                    }px)`;
                }

                if (scrollPos >= tolerance) {
                    console.log('Go!');
                    e.target.removeEventListener('scroll', onScroll);
                    setIntro(false);
                }
            }
        },
        [introReady]
    );

    const scrollerRef = useCallback(
        (node) => {
            if (node) {
                // const onScroll = (e: Event) => {
                //     if (e && e.target) {
                //         const scrollPos = (e.target as HTMLDivElement).scrollTop;
                //         const tolerance = 300; // px until the intro screen goes away.
                //         const opacityTotalChange = 0.5;
                //         const translateYTotalChange = 100;

                //         // Animate the intro content while scrolling.
                //         if (introContentRef && introContentRef.current) {
                //             const opacityPerPx = opacityTotalChange / tolerance; // Total opacity change 0.5
                //             const translateYPerPx =
                //                 translateYTotalChange / tolerance; // Total translateY change 25px;

                //             (e.target as HTMLElement).style.opacity = `${
                //                 1 - opacityPerPx * scrollPos
                //             }`;
                //             // introContentRef.current.style.opacity = `${1 - (opacityPerPx * scrollPos)}`;
                //             introContentRef.current.style.transform = `translateY(-${
                //                 translateYPerPx * scrollPos
                //             }px)`;
                //         }

                //         if (scrollPos >= tolerance) {
                //             console.log('Go!');
                //             e.target.removeEventListener('scroll', onScroll);
                //             setIntro(false);
                //         }
                //     }
                // };

                const onEnter = (e: KeyboardEvent) => {
                    if (e.which === 13 || e.keyCode === 13) {
                        setIntro(false);
                        window.removeEventListener('keyup', onEnter);
                    }
                };

                onScroll && node.addEventListener('scroll', onScroll);
                window.addEventListener('keyup', onEnter);
            }
        },
        [onScroll]
    );

    useEffect(() => {
        // Fetch the location data.
        // Start the authenticator and sign in anonymously.
        const init = async () => {
            authenticate();

            const locations = await fetchLocations();

            // Preload the first 3 card images, and all placeholders.
            locations
                .map((loc: Location) => [
                    loc.location_card_data?.card_image_placeholder?.url,
                    loc.location_card_data?.card_image_default?.url,
                ])
                .forEach((src: any[], i) => {
                    if (i < 3) {
                        src[1] && preloadImage(src[1]);
                    }
                    src[0] && preloadImage(src[0]);
                });
        };

        init();
    }, []);

    useEffect(() => {
        // If user is undefined, session has not been determined.
        // If a user exists, fetch the data that requires authentication.
        // Else, sign in anonymously.
        const fetch = async () => {
            await fetchFavorites();
        };

        if (user === undefined) return;

        if (user) {
            // fetch(); // No longer need to do this because data is fetched on auth change.
        } else {
            signInAnon();
        }
    }, [user, userData]);

    useEffect(() => {
        // Preload about modal images
        if (journey?.about_modal?.image) {
            const {
                default_image_url,
                mobile_image_url,
            } = journey?.about_modal?.image;
            default_image_url && preloadImage(default_image_url);
            isMobile && mobile_image_url && preloadImage(mobile_image_url);
        }
    }, [journey?.about_modal]);

    useEffect(() => {
        // If journey intro_screen setting is definitively false, and we're on the intro, set intro to false.
        if (journey && !journey?.intro_screen && intro === true) {
            setIntro(false);
        }
    }, [journey, intro]);

    // useEffect(() => {
    //     if (!journey) return;

    //     let image;
    //     if (isMobile && journey?.intro_bg_image?.mobile_image_url) {
    //         image = preloadImage(
    //             journey?.intro_bg_image?.mobile_image_url as string
    //         );
    //     } else if (journey?.intro_bg_image?.default_image_url) {
    //         image = preloadImage(
    //             journey?.intro_bg_image?.default_image_url as string
    //         );
    //     } else {
    //         setIntroImageLoaded(true);
    //     }

    //     if (image) {
    //         image.onload = () => {
    //             setIntroImageLoaded(true);
    //         };
    //     }
    // }, [journey]);

    useEffect(() => {
        if (assetsLoaded && (isSafari || videoReady) && !introReady) {
            setTimeout(() => {
                setIntroReady(true);
            }, 400);
        }
    }, [assetsLoaded, videoReady, introReady]);

    const wrapFullScreen = (children: ReactElement) => {
        return fullScreenHandler ? (
            <FullScreen handle={fullScreenHandler}>{children}</FullScreen>
        ) : (
            children
        );
    };

    // const IntroResponsiveImage =
    //     (isMobile && journey?.intro_bg_image?.mobile_image_url) ||
    //     journey?.intro_bg_image?.default_image_url ? (
    //         <ResponsiveImage {...journey.intro_bg_image} />
    //     ) : null;

    // const introScreenBgStyle = (() => {
    //     // If journey hasn't loaded
    //     // Or if journey exists and there is an intro image
    //     // background is black
    //     if (!journey || (journey && IntroResponsiveImage)) {
    //         return { background: 'black' };
    //     } else {
    //         return {};
    //     }
    // })();

    const introMessages = useMemo(
        () => [
            {
                strings: [...(journey?.intro_messages || []), ''],
                fadeOut: true,
                fadeOutClass: 'typed-fade-out',
                fadeOutDelay: 500,
            },
            {
                strings: [
                    `${
                        portrait ? 'Swipe' : 'Scroll'
                    } the cards, or explore using the map.^3000\n This message will self-destruct in 3`,
                    `${
                        portrait ? 'Swipe' : 'Scroll'
                    } the cards, or explore using the map.\n This message will self-destruct in 2`,
                    `${
                        portrait ? 'Swipe' : 'Scroll'
                    } the cards, or explore using the map.\n This message will self-destruct in 1`,
                ],
            },
        ],
        [journey, portrait]
    );

    return (
        <>
            {wrapFullScreen(
                <>
                    {(!journey || intro) && (
                        <div
                            className='IntroScreen'
                            ref={scrollerRef}
                            // style={introScreenBgStyle}
                        >
                            <div className='Scroller'></div>
                            <div
                                className={`Content${
                                    !introReady ? ' loading' : ''
                                }`}
                                ref={introContentRef}
                            >
                                {!introReady && (
                                    <div className='LoaderWrapper'>
                                        <Loader />
                                    </div>
                                )}
                                {/* <div className='ContentWrapper anchor-top'>
                                    {journey?.intro_video ? (
                                        <HTMLVideo
                                            {...{
                                                playerProps: {
                                                    playing: true,
                                                    loop: true,
                                                    muted: true,
                                                    light: false,

                                                    onReady: () => {
                                                        setVideoReady(true);
                                                    },
                                                },
                                                sources:
                                                    journey.intro_video.sources,
                                                sourcesVertical:
                                                    journey.intro_video
                                                        .sources_vertical,
                                            }}
                                        />
                                    ) : null}
                                    <div className='KnockoutText'>
                                        <h1>WONDER</h1>
                                        <h2>CALIFORNIA</h2>
                                    </div>
                                    <div className='Description'>
                                        <LineAnimation />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    )}
                    {!intro && (
                        <div
                            className={`Message ${
                                message.active ? '' : 'hide'
                            }`}
                        >
                            <button
                                className='Close'
                                onClick={hideMessage}
                            ></button>
                            {message.content}
                        </div>
                    )}
                    {!intro && modal && modal}
                    {!intro && locations && (
                        <Route path='/'>
                            <Suspense fallback={null}>
                                <ActionMenu />
                            </Suspense>
                        </Route>
                    )}
                    {locations && locations?.length > 0 && (
                        <Switch>
                            <Route path={`${path}/explore/:path`}>
                                <Suspense fallback={null}>
                                    <Explore locations={locations} />
                                </Suspense>
                            </Route>
                            {((isMobile && !intro) || !isMobile) && (
                                <Route path={path}>
                                    <Suspense fallback={null}>
                                        <IntroMessage
                                            messagesWithOptions={introMessages}
                                            typeSpeed={20}
                                            backSpeed={2}
                                        />
                                    </Suspense>
                                    <Suspense fallback={null}>
                                        <MapComponent
                                            intro={intro}
                                            journey={journey}
                                            locations={filteredLocations}
                                        />
                                    </Suspense>
                                </Route>
                            )}
                        </Switch>
                    )}
                </>
            )}
        </>
    );
}
