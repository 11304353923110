import React, {
    createContext,
    FunctionComponent,
    ReactElement,
    useState,
} from 'react';

export type ModalContextType = {
    // State
    modal: ReactElement | null;

    // Actions
    setModal: (modal?: ReactElement | null) => void;
};

const modalDefaultState = {
    modal: null,

    setModal: () => {},
};
export const ModalContext = createContext<ModalContextType>(modalDefaultState);
export const ModalConsumer = ModalContext.Consumer;

interface ModalContextWrapperProps {
    children: any;
}
export const ModalContextWrapper: FunctionComponent<ModalContextWrapperProps> = (
    props: ModalContextWrapperProps
) => {
    const { modal: defaultModal } = modalDefaultState;

    const [modalContent, setModalContent] = useState<ModalContextType['modal']>(
        defaultModal
    );

    const setModal: ModalContextType['setModal'] = (modal) => {
        setModalContent(modal || null);
    };

    const store = {
        modal: modalContent,

        setModal,
    };

    return (
        <ModalContext.Provider value={store}>
            {props.children}
        </ModalContext.Provider>
    );
};
