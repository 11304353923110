import React, { FC, useContext, useEffect, useState, useCallback } from 'react';

import { ThemeProvider } from 'theme-ui';
import WebFont from 'webfontloader';

import { DataContext } from './DataContext';
import { Journey } from '../types/Journey';
import { useMemo } from 'react';

interface ThemeContextWrapperProps {
    children: any;
}

const defaultThemeState = {
    colors: {
        primary: '',
        secondary: '',
    },
    fonts: {
        body: 'Arial, sans-serif',
        heading: 'Arial, sans-serif',
    },
    mapStyle: {
        standard: '',
        safari: '',
    },
    featureOutline: {
        paint: '',
    },
    featureShadow: {
        paint: '',
    },
};

export const ThemeContextWrapper: FC<ThemeContextWrapperProps> = (props) => {
    const { journey, assetsLoaded, setAssetsLoaded } = useContext(DataContext);
    const [mapStyle, setMapStyle] = useState({
        standard: defaultThemeState.mapStyle.standard,
        safari: defaultThemeState.mapStyle.safari,
    });
    const [colors, setColors] = useState({
        primary: defaultThemeState.colors.primary,
        secondary: defaultThemeState.colors.secondary,
    });
    const [fonts, setFonts] = useState({
        body: defaultThemeState.fonts.body,
        heading: defaultThemeState.fonts.heading,
    });
    const [featureOutline, setFeatureOutline] = useState(
        () => defaultThemeState.featureOutline.paint
    );
    const [featureShadow, setFeatureShadow] = useState(
        () => defaultThemeState.featureShadow.paint
    );

    const theme = useMemo(
        () => ({
            colors,
            fonts,
            mapStyle,
            featureOutline,
            featureShadow,
        }),
        [colors, fonts, mapStyle, featureOutline, featureShadow]
    );

    const loadFonts = useCallback(() => {
        const {
            theme: {
                fonts: { body: bodyData, heading: headingData },
            },
        } = journey as Journey;
        const [body] = bodyData;
        const [heading] = headingData;
        let webFontConfig: {
            [key: string]: any;
        } = {
            classes: false,
            fontactive: (fontName: string) => {
                const newFontObj: {
                    [key: string]: any;
                } = {};
                if (fontName === body.google_font) {
                    newFontObj.body = `${body.google_font}, Helvetica, Arial, sans-serif`;
                }
                if (fontName === heading.google_font) {
                    newFontObj.heading = `${heading.google_font}, Helvetica, Arial, sans-serif`;
                }
                setFonts((prev) => {
                    return {
                        ...prev,
                        ...newFontObj,
                    };
                });
            },
            google: {
                families: [],
            },
        };
        if (body?.google_font) {
            webFontConfig.google.families.push(body.google_font);
        }
        if (heading?.google_font) {
            webFontConfig.google.families.push(heading.google_font);
        }
        webFontConfig.google.families = [
            ...new Set(webFontConfig.google.families),
        ];
        WebFont.load(webFontConfig);
    }, [journey]);
    const loadColors = useCallback(() => {
        setColors(() => {
            const {
                theme: {
                    colors: {
                        primary_color: { hex: primary },
                        secondary_color: { hex: secondary },
                    },
                },
            } = journey as Journey;
            return {
                primary,
                secondary,
            };
        });
    }, [journey]);
    const loadMapStyleUrl = useCallback(() => {
        setMapStyle(() => {
            const {
                theme: {
                    map_styles: {
                        map_style_default: standard,
                        map_style_safari: safari,
                    },
                },
            } = journey as Journey;
            return {
                standard,
                safari,
            };
        });
    }, [journey]);
    const loadFeatureOutlinePaint = useCallback(() => {
        setFeatureOutline(() => {
            const {
                theme: {
                    map_styles: { feature_outline },
                },
            } = journey as Journey;
            return feature_outline;
        });
    }, [journey]);
    const loadFeatureShadowPaint = useCallback(() => {
        setFeatureShadow(() => {
            const {
                theme: {
                    map_styles: { feature_shadow },
                },
            } = journey as Journey;
            return feature_shadow;
        });
    }, [journey]);

    useEffect(() => {
        if (journey) {
            loadFonts();
            loadColors();
            loadMapStyleUrl();
            loadFeatureOutlinePaint();
            loadFeatureShadowPaint();
        }
    }, [journey]);

    useEffect(() => {
        const bodyStyle = `
            * {
                font-family: ${theme.fonts.body};
            }
        `;

        const headingStyle = `
            h1, h2, h3, h4, h4, h5 {
                font-family: ${theme.fonts.heading};
            }
        `;
        const styleContent = `
            ${bodyStyle}
            ${headingStyle}
        `;

        const head = document.head || document.getElementsByTagName('head')[0];
        const styleTag = document.createElement('style');
        styleTag.type = 'text/css';
        styleTag.appendChild(document.createTextNode(styleContent));
        head.appendChild(styleTag);

        return () => {
            head.removeChild(styleTag);
        };
    }, [theme]);

    useEffect(() => {
        if (journey && fonts && !assetsLoaded) {
            const body = journey.theme?.fonts?.body?.[0]?.google_font || '';
            const heading =
                journey.theme?.fonts?.heading?.[0]?.google_font || '';
            const allLoaded =
                fonts.body.includes(body) && fonts.heading.includes(heading);

            if (allLoaded) {
                setAssetsLoaded(true);
            }
        }
    }, [journey, fonts, assetsLoaded]);

    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
