import sanityClient from '@sanity/client';

const sandboxParam =
    new URLSearchParams(window.location.search).get('sandbox') !== null;

sandboxParam &&
    window.sessionStorage &&
    window.sessionStorage.setItem('sandbox', 1);

export const sandboxMode = window.sessionStorage
    ? window.sessionStorage.getItem('sandbox')
    : null;

export const config = {
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: sandboxMode ? 'sandbox' : 'production',
    useCdn: sandboxMode
        ? false
        : Number(process.env.REACT_APP_SANITY_CACHE) || true,
};

const sanity = sanityClient(config);

export default sanity;
