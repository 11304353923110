import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    FunctionComponent,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import Firebase from 'firebase';

import { User as UserData } from '../types/User';
import firebase from '../utils/firebase';
import { MessageContext } from './MessageContext';
import { sandboxMode } from '../utils/sanity';

let onAuthStateChangedHandler: Unsubscribe | null = null;

type User = Firebase.User;
type Unsubscribe = Firebase.Unsubscribe;

export interface UserSignUpData {
    email: string;
    firstName?: string;
    lastName?: string;
}

export async function getAuthToken(
    user: User | null = firebase.auth().currentUser
) {
    // Check firebase for current user and token.
    const token = user ? await user.getIdToken() : null;

    return token || null;
}

export async function getEmailUsed(email: string) {
    const emailProviders = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email);
    return emailProviders?.length > 0;
}

export type AuthContextType = {
    // useLoading: [boolean, Dispatch<SetStateAction<boolean>>],

    error: string | null;
    // loggedInThisSession: boolean;
    token: string | null | undefined; // null == does not exist | undefined == undetermined
    updateUserData: {
        data: UserData;
        clear: () => void;
    } | null;
    user: User | null | undefined; // null == does not exist | undefined == undetermined, remember that user can be anonymous so existence of user doesn't mean signed in

    authenticate: () => Unsubscribe | null;
    signIn: (email: string, password: string) => Promise<User | null | void>;
    signInAnon: () => Promise<User | null | void>;
    signInWithEmailLink: (
        data: UserSignUpData,
        newAccount?: boolean
    ) => Promise<boolean>;
    signOut: () => Promise<void>;
};

// Create context with some defaults...
const authDefaultState: AuthContextType = {
    // useLoading: [true, () => {}],

    error: null,
    // loggedInThisSession: false,
    token: undefined,
    updateUserData: null,
    user: undefined,

    authenticate: () => null,
    signIn: async () => null,
    signInAnon: async () => null,
    signInWithEmailLink: async () => false,
    signOut: async () => {},
};
export const AuthContext = createContext<AuthContextType>(authDefaultState);

interface AuthContextWrapperProps {
    children: any;
}

// Create state.
export const AuthContextWrapper: FunctionComponent<AuthContextWrapperProps> = ({
    children,
}: AuthContextWrapperProps) => {
    const {
        // useLoading: [ defaultLoading ],

        error: defaultError,
        // loggedInThisSession: defaultLoggedInThisSession,
        token: defaultToken,
        updateUserData: defaultupdateUserData,
        user: defaultUser,
    } = authDefaultState;

    const [error, setError] = useState<string | null>(defaultError);
    // const useLoading = useState<boolean>(defaultLoading);
    // const [loggedInThisSession, setLoggedInThisSession] = useState<boolean>(
    //     defaultLoggedInThisSession
    // );
    const [token, setToken] = useState<AuthContextType['token']>(defaultToken);
    const [updateUserData, setUpdateUserData] = useState<
        AuthContextType['updateUserData']
    >(defaultupdateUserData);
    const [user, setUser] = useState<AuthContextType['user']>(defaultUser);

    // Signs in as an email-verified user
    const [upgradeUser, setUpgradeUser] = useState<{
        credential: Firebase.auth.AuthCredential;
        data: UserSignUpData;
    } | null>(null);

    const { setMessage } = useContext(MessageContext);

    const location = useLocation();
    const history = useHistory();
    const query = new URLSearchParams(location.search);

    const handleAuthChange = (u: User | null) => {
        // Handle user change.
        if (u) {
            // A user is signed in.
            console.log('A user has been authenticated.', u.uid);
            u.getIdToken().then((token) => {
                if (token) {
                    setToken(token);
                    setUser(u);

                    // if (!u.isAnonymous) {
                    //     setLoggedInThisSession(true);
                    // }
                }
            });
        } else {
            // No user.
            setToken(null);
            setUser(null);
        }

        return u;
    };

    const handleEmailLink = async (emailLink: string) => {
        try {
            const data: UserSignUpData = JSON.parse(emailLink);
            const isLink = firebase
                .auth()
                .isSignInWithEmailLink(window.location.href);

            if (isLink) {
                const { email } = data;

                const credential = Firebase.auth.EmailAuthProvider.credentialWithLink(
                    email,
                    window.location.href
                );

                if (credential) {
                    setUpgradeUser({ credential, data });
                } else {
                    throw new Error('Credential invalid or expired.');
                }
            }
        } catch (e) {
            setMessage('There was an issue signing in with that email link.');
        }
    };

    const handleUpgradeUser = async (upgradeUser: {
        credential: Firebase.auth.AuthCredential;
        data: UserSignUpData;
    }) => {
        const currentUser = firebase.auth().currentUser as User;
        const { credential, data } = upgradeUser;
        const { firstName, lastName } = data;
        try {
            const emailUsed = await getEmailUsed(data.email);
            if (!emailUsed) {
                // Link
                await currentUser.linkWithCredential(credential);
                setUpdateUserData({
                    data: {
                        firstName,
                        lastName,
                    },
                    clear: () => {
                        setUpdateUserData(null);
                    },
                });
            } else {
                // Sign in
                await firebase.auth().signInWithCredential(credential);
            }
        } catch (e) {
            if (e.code === 'auth/invalid-action-code') {
                setMessage(
                    'The sign-in email link you used is expired or already used.'
                );
            } else {
                setMessage('There was an issue validating your email link.');
            }
        } finally {
            // Clear upgrade user credentials and query params
            setUpgradeUser(null);
            history.replace(location.pathname);
        }
    };

    // Starts the authentication observer. For some reason this only works when called from inside a component, rather than on call of this useAuth function.
    // We must use this authentication observer onAuthStateChanged to set the current user, because accessing firebase.auth().currentUser from anywhere before the auth module has initialized will return null.
    const authenticate: AuthContextType['authenticate'] = () => {
        onAuthStateChangedHandler = firebase
            .auth()
            .onAuthStateChanged(handleAuthChange);

        return onAuthStateChangedHandler;
    };

    // Sign in with email and password.
    const signIn: AuthContextType['signIn'] = async (email, password) => {
        console.log('Signing in as user.');
        try {
            const req = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password);

            const currentUser = req.user;

            const token = await getAuthToken(currentUser);

            if (!token) {
                throw new Error('No valid token.');
            }

            return currentUser;
        } catch (e) {
            setMessage('There was an issue signing in.');
        }
    };

    // Sign in anonymously.
    const signInAnon: AuthContextType['signInAnon'] = async () => {
        try {
            const existingToken = await getAuthToken();

            if (existingToken) {
                return;
            }

            console.log('Signing in anonymously.');
            const req = await firebase.auth().signInAnonymously();

            const currentUser = req.user;

            const token = await getAuthToken(currentUser);

            if (!token) {
                throw new Error('No valid token.');
            }

            return currentUser;
        } catch (e) {
            setMessage('There was an issue creating a new session for you.');
        }
    };

    // Sign in with email link.
    const signInWithEmailLink: AuthContextType['signInWithEmailLink'] = async (
        data,
        newAccount = true
    ) => {
        if (newAccount) {
            if (!(data?.firstName && data?.lastName)) {
                setError('First name and last name are required to subscribe.');
                return false;
            }
        }

        const dataString = JSON.stringify(data);
        const settings = {
            url: `${window.location.origin}/?emailLink=${encodeURIComponent(
                dataString
            )}${sandboxMode ? '&sandbox' : ''}`,
            handleCodeInApp: true,
        };

        try {
            await firebase.auth().sendSignInLinkToEmail(data.email, settings);
            return true;
        } catch (e) {
            setMessage('There was an issue sending your magic link.');
            return false;
        }
    };

    // Sign out.
    const signOut: AuthContextType['signOut'] = async () => {
        await firebase.auth().signOut();
    };

    const store: AuthContextType = {
        // These return an iterable [state, setState] like useState, which allows read-write within the component.
        // useLoading,

        // These are only return the current state, and are read-only.
        error,
        // loggedInThisSession,
        token,
        user,
        updateUserData,

        // These are action methods.
        authenticate,
        signIn,
        signInAnon,
        signInWithEmailLink,
        signOut,
    };

    // Handle email link. This will only happen on app load once.
    useEffect(() => {
        const emailLink = query.get('emailLink');
        if (emailLink) {
            handleEmailLink(emailLink);
        }
    }, []);

    // Handle email link authenticated credentials.
    useEffect(() => {
        if (user && upgradeUser) {
            handleUpgradeUser(upgradeUser);
        }
    }, [upgradeUser, user]);

    useEffect(() => {
        window.sendEmail = () =>
            signInWithEmailLink({
                email: 'john@wondermapper.com',
                firstName: 'John',
                lastName: 'Vu',
            });
    }, []);

    return (
        <AuthContext.Provider value={store}>{children}</AuthContext.Provider>
    );
};
