import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import firebaseConfig from '../firebase.config.json';

const app = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === 'localhost') {
    app.firestore().useEmulator('localhost', 8080);
    app.auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default app;
