import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
    FunctionComponent,
} from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router';

import { Journey } from '../types/Journey';
import { Location } from '../types/Location';
import { User } from '../types/User';

import { AuthContext } from './AuthContext';
import { MessageContext } from './MessageContext';

import firestore, { instantiateUser, makeUserPath } from '../utils/firestore';
import sanity from '../utils/sanity';
import { SignUpModal } from '../components/SignUpModal';
import { ModalContext } from './ModalContext';

const projectId = process.env.REACT_APP_SANITY_PROJECT_ID as string;

let signUpModalSeenGlobal = window.sessionStorage
    ? window.sessionStorage.getItem('signUpModalSeen') || false
    : false;

export type DataContextType = {
    error: string | null;
    journey: Journey | null;
    locations: Location[] | null;
    favorites: {
        [key: string]: string[] | null;
        my: string[] | null;
    };
    likes: {
        [key: string]: boolean;
    };
    subscriptions: {
        [key: string]: boolean | undefined;
    };
    userData: User | null | undefined; // null == does not exist | undefined == undetermined
    otherUserData: {
        [key: string]: User | null;
    };
    assetsLoaded: boolean;

    fetchLocations: () => Promise<Location[]>;
    fetchFavorites: (uid?: string) => Promise<string[]>;
    toggleFavorite: (id: string) => Promise<string[]>;
    toggleLikes: (id: string, state: boolean) => Promise<boolean | undefined>;
    setAssetsLoaded: (state: boolean) => void;
};

const dataDefaultState: DataContextType = {
    error: null,
    journey: null,
    locations: null,
    favorites: {
        my: null,
    },
    likes: {},
    subscriptions: {
        general: undefined,
        specific: undefined,
    },
    userData: undefined,
    otherUserData: {},
    assetsLoaded: false,

    fetchLocations: async () => [],
    fetchFavorites: async () => [],
    toggleFavorite: async () => [],
    toggleLikes: async () => true,
    setAssetsLoaded: () => {},
};

export const DataContext = createContext<DataContextType>(dataDefaultState);

interface DataContextWrapperProps {
    children: any;
}
// Create state.
export const DataContextWrapper: FunctionComponent<DataContextWrapperProps> = ({
    children,
}: DataContextWrapperProps) => {
    const { user, updateUserData } = useContext(AuthContext);
    const { setMessage } = useContext(MessageContext);
    const { setModal } = useContext(ModalContext);
    const routeParams = useParams() as { [key: string]: string };
    const { search } = useLocation();
    const params = queryString.parse(search, { arrayFormat: 'bracket' });

    const { uid } = user || { uid: null };

    const {
        error: defaultError,
        journey: defaultJourney,
        locations: defaultLocations,
        favorites: defaultFavorites,
        likes: defaultLikes,
        userData: defaultUserData,
        otherUserData: defaultOtherUserData,
        assetsLoaded: defaultAssetsLoaded,
    } = dataDefaultState;

    const [error, setError] = useState<DataContextType['error']>(defaultError);
    const [journey, setJourney] = useState<DataContextType['journey']>(
        defaultJourney
    );
    const [locations, setLocations] = useState<DataContextType['locations']>(
        defaultLocations
    );
    const [favorites, setFavorites] = useState<DataContextType['favorites']>(
        defaultFavorites
    );
    const [likes, setLikes] = useState<DataContextType['likes']>(defaultLikes);
    const [userData, setUserData] = useState<DataContextType['userData']>(
        defaultUserData
    );
    const [otherUserData, setOtherUserData] = useState<
        DataContextType['otherUserData']
    >(defaultOtherUserData);
    const [assetsLoaded, setAssetsLoaded] = useState<
        DataContextType['assetsLoaded']
    >(defaultAssetsLoaded);
    const [generalSubscription, setGeneralSubscription] = useState<boolean>(
        false
    );
    const [specificSubscription, setSpecificSubscription] = useState<boolean>(
        false
    );
    const subscriptions = {
        general: generalSubscription,
        specific: specificSubscription,
    };

    const handleUserChange = async (uid: string) => {
        // This prepares the user data and creates missing data if needed.
        try {
            const path = makeUserPath(uid);
            const userDoc = await firestore.doc(path).get();
            if (!userDoc.exists) {
                // No need to set here, it gets handled below onSnapshot.
                await instantiateUser(uid);
                console.log('Instantiated new user.');
            } else {
                const data = userDoc.data();
                setUserData({
                    ...data,
                    uid,
                } as User);
            }

            if (user?.email) {
                const subscriptionDocs = await Promise.all([
                    firestore.doc(`${path}/subscriptions/general`).get(),
                    firestore.doc(`${path}/subscriptions/${projectId}`).get(),
                ]);

                const generalSubscriptionDoc = subscriptionDocs[0];
                if (!generalSubscriptionDoc.exists) {
                    await firestore.doc(`${path}/subscriptions/general`).set({
                        subscribed: true,
                    });
                    setGeneralSubscription(true);
                } else {
                    const generalSubscription = generalSubscriptionDoc.data();
                    setGeneralSubscription(generalSubscription?.subscribed);
                }

                const specificSubscriptionDoc = subscriptionDocs[1];
                if (!specificSubscriptionDoc.exists) {
                    await firestore
                        .doc(`${path}/subscriptions/${projectId}`)
                        .set({
                            subscribed: true,
                        });
                    setSpecificSubscription(true);
                } else {
                    const specificSubscription = specificSubscriptionDoc.data();
                    setSpecificSubscription(specificSubscription?.subscribed);
                }
            }

            if (user?.email && !params.filter) {
                setMessage(
                    <>
                        <p>
                            <b>Welcome back!</b>
                        </p>
                        <p>You're signed in as {user.email}</p>
                    </>
                );
            }
        } catch (e) {
            setError(e);
        }
    };

    // NOTE: nested location properties cannot be accessed by Mapbox Style Expressions.
    const fetchLocations: DataContextType['fetchLocations'] = async () => {
        const query = `
        *[_type == 'journey' && slug.current == 'wondermapper'] {
            intro_messages,
			intro_bounding_box_feature,
            journey_overlay_area_feature,
            locations[] -> {
                'id': _id,
                live,
                name,
                explore_intro,
				location_geojson,
                'location_map_icon_url': location_map_icon.asset->url,
                'location_map_icon_name': location_map_icon.asset->originalFilename,
                location_card_data {
                    card_image_default {
                        'url': asset->url,
                        'name': asset->originalFilename,
                        'description': description
                    },
                    card_image_placeholder {
                        'url': asset->url,
                        'name': asset->originalFilename,
                        'description': description
                    },
                    short_description,
                    tags
                },
                scroll_content[] {
                    ...,
					_type == 'responsive_image_block' => {
						'responsive_image': {
							'default_image_url': responsive_image.default_image.asset->url,
							'default_placeholder_url': responsive_image.default_image.asset->url,
							'mobile_image_url': responsive_image.mobile_image.asset->url,
							'mobile_placeholder_url': responsive_image.mobile_placeholder.asset->url,
                        }
					},
                    _type == 'video_block' => {
                        video {
                            ...,
                            sources[] {
                                ...,
                                _type == 'file' => {
                                    'url': asset->url
                                }
                            },
                            sources_vertical[] {
                                ...,
                                _type == 'file' => {
                                    ...,
                                    'url': asset->url
                                }
                            }
                        }
                    }
                },
                'slug': slug.current
            },
            mystery_locations[] -> {
                'id': _id,
                live,
                name,
                'slug': slug.current,
                location_geojson,
                'location_map_icon_url': location_map_icon.asset->url,
                'location_map_icon_name': location_map_icon.asset->originalFilename,
                'image': {
                    'default_image_url': image.default_image.asset->url,
                    'default_placeholder_url': image.default_image.asset->url,
                    'mobile_image_url': image.mobile_image.asset->url,
                    'mobile_placeholder_url': image.mobile_placeholder.asset->url,
                }
            },
            numbered,
            short_description,
            slug,
            title,
			theme,
            about_modal {
                ...,
                'image': {
                    'default_image_url': image.default_image.asset->url,
                    'default_placeholder_url': image.default_image.asset->url,
                    'mobile_image_url': image.mobile_image.asset->url,
                    'mobile_placeholder_url': image.mobile_placeholder.asset->url,
                }
            }
        }
        `;

        try {
            const res = await sanity.fetch(query);
            console.log('Journey:', res);
            if (!res || res.length === 0) {
                setError(`We can't find that journey.`);
                return [];
            } else {
                setJourney(res[0]);
                setLocations(res[0].locations);
                return res[0].locations;
            }
        } catch (e) {
            setError(e.message);
            return [];
        }
    };

    // This fetches the favorites for a target uid. It also saves the user's general data to state as well.
    const fetchFavorites: DataContextType['fetchFavorites'] = useCallback(
        async (uid) => {
            try {
                if (user) {
                    const targetUid = uid || user.uid;
                    const path = makeUserPath(targetUid);
                    const docSnapshot = await firestore.doc(path).get();

                    if (!docSnapshot.exists) {
                        if (targetUid === user.uid) {
                            try {
                                await handleUserChange(targetUid);
                            } catch (e) {
                                setFavorites({
                                    ...favorites,
                                    my: [],
                                });
                            }
                        } else {
                            setOtherUserData({
                                ...otherUserData,
                                [targetUid]: null,
                            });
                            setFavorites({
                                ...favorites,
                                [targetUid]: [],
                            });
                        }

                        return [];
                    } else {
                        const userData: User = docSnapshot.data() as User;

                        const projectFavorites =
                            userData?.favorites?.[projectId] || [];

                        if (targetUid !== user.uid) {
                            setOtherUserData({
                                ...otherUserData,
                                [targetUid]: userData,
                            });
                        }

                        setFavorites({
                            ...favorites,
                            [targetUid === user.uid
                                ? 'my'
                                : targetUid]: projectFavorites,
                        });

                        return projectFavorites;
                    }
                } else {
                    throw new Error(
                        'No user, make sure to wait until user is authenticated.'
                    );
                }
            } catch (e) {
                setError(e.message);
                return [];
            }
        },
        [user, favorites, otherUserData]
    );

    const toggleFavorite: DataContextType['toggleFavorite'] = async (id) => {
        try {
            if (user && favorites) {
                const newFavorites = [...(favorites?.my || [])];

                const inFavorites = newFavorites.indexOf(id);
                if (inFavorites > -1) {
                    // Remove
                    setMessage('Removed from favorites');
                    newFavorites.splice(inFavorites, 1);
                } else {
                    // Add
                    setMessage('Added to favorites ❤️');
                    newFavorites.push(id);
                }

                let signUpModalSeen;
                if (window.sessionStorage) {
                    signUpModalSeen = !!window.sessionStorage.getItem(
                        'signUpModalSeen'
                    );
                } else {
                    signUpModalSeen = signUpModalSeenGlobal;
                }
                user.isAnonymous &&
                    !signUpModalSeen &&
                    setTimeout(() => {
                        setModal(<SignUpModal page='subscribe' />);
                        window.sessionStorage &&
                            window.sessionStorage.setItem(
                                'signUpModalSeen',
                                '1'
                            );
                    }, 800);

                const path = makeUserPath(user.uid);
                await firestore.doc(path).update({
                    [`favorites.${projectId}`]: newFavorites,
                });

                return newFavorites;
            } else {
                throw new Error(
                    'Make sure to wait until user is authenticated and data has loaded.'
                );
            }
        } catch (e) {
            setError(e.message);
            return [];
        }
    };

    const toggleLikes: DataContextType['toggleLikes'] = async (id, state) => {
        try {
            if (user && likes) {
                const newLikes = { ...likes };

                newLikes[id] = state;

                const path = makeUserPath(user.uid);
                await firestore.doc(path).update({
                    [`likes.${projectId}`]: newLikes,
                });

                return state;
            } else {
                throw new Error(
                    'Make sure to wait until user is authenticated and data has loaded.'
                );
            }
        } catch (e) {
            setError(e.message);
            return undefined;
        }
    };

    // When user uid updates, update watchers.
    useEffect(() => {
        if (uid) {
            const path = makeUserPath(uid);
            handleUserChange(uid);

            // Watch user
            const stopWatchingUser = firestore.doc(path).onSnapshot(
                (doc) => {
                    const data = doc.data();
                    setUserData({
                        ...data,
                        uid,
                    } as User);
                },
                (err) => {
                    console.warn(err);
                }
            );

            // Watch subscriptions
            const stopWatchingGeneralSub = firestore
                .doc(`${path}/subscriptions/general`)
                .onSnapshot(
                    (doc) => {
                        const data = doc.data();
                        const subscribed = data?.subscribed;
                        setGeneralSubscription(subscribed);
                    },
                    (err) => {
                        console.warn(err);
                    }
                );
            const stopWatchingSpecificSub = firestore
                .doc(`${path}/subscriptions/${projectId}`)
                .onSnapshot(
                    (doc) => {
                        const data = doc.data();
                        const subscribed = data?.subscribed;
                        setSpecificSubscription(subscribed);
                    },
                    (err) => {
                        console.warn(err);
                    }
                );

            // Stop watching
            return () => {
                stopWatchingUser();
                stopWatchingGeneralSub();
                stopWatchingSpecificSub();
            };
        }
    }, [uid]);

    // When userData updates, update favorites and likes.
    useEffect(() => {
        if (userData) {
            const myFavorites = userData.favorites || {};
            setFavorites({
                ...favorites,
                my: myFavorites[projectId] || [],
            });

            const myLikes = userData.likes?.[projectId] || {};
            setLikes(myLikes);
        }
    }, [userData]);

    useEffect(() => {
        const updateData = async () => {
            // When userData and updateUserData exist, and current uid matches.
            if (userData && updateUserData && userData.uid === uid) {
                try {
                    const path = makeUserPath(uid);
                    updateUserData.clear();
                    // If userData firstName and lastName don't exist...
                    if (!(userData.firstName && userData.lastName)) {
                        await firestore.doc(path).update({
                            ...updateUserData.data,
                        });
                    }

                    const subscriptionsCollection = await firestore
                        .collection(`${path}/subscriptions`)
                        .get();
                    if (subscriptionsCollection.empty) {
                        await firestore
                            .doc(`${path}/subscriptions/general`)
                            .set({
                                subscribed: true,
                            });
                    }
                } catch (e) {
                    setError(e);
                }
            }
        };

        updateData();
    }, [userData, updateUserData, uid]);

    const store: DataContextType = {
        // These return an iterable [state, setState] like useState, which allows read-write within the component.
        // useLoading,

        // These are only return the current state, and are read-only.
        error,
        journey,
        locations,
        favorites,
        likes,
        subscriptions,
        userData,
        otherUserData,
        assetsLoaded,

        // These are action methods.
        fetchLocations,
        fetchFavorites,
        toggleFavorite,
        toggleLikes,
        setAssetsLoaded,
    };

    return (
        <DataContext.Provider value={store}>{children}</DataContext.Provider>
    );
};
