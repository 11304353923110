import React, { ChangeEvent, FC, FormEvent, useContext, useState } from 'react';
import { useEffect } from 'react';
import {
    AuthContext,
    getEmailUsed,
    UserSignUpData,
} from '../contexts/AuthContext';
import { ModalContext } from '../contexts/ModalContext';
import { MessageContext } from '../contexts/MessageContext';
import { useThemeUI } from 'theme-ui';
import { isMobile } from '../utils/utils';

export interface SignUpModalProps {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    customMessage?: string | null;
    page: 'subscribe' | 'signin' | 'sent';
}

export const SignUpModal: FC<SignUpModalProps> = ({
    firstName: defaultFirstName = null,
    lastName: defaultLastName = null,
    email: defaultEmail = null,
    customMessage = null,
    page: defaultPage,
}) => {
    const { setModal } = useContext(ModalContext);
    const { signInWithEmailLink } = useContext(AuthContext);
    const { setMessage } = useContext(MessageContext);
    const { theme } = useThemeUI() as any;

    const [firstName, setFirstName] = useState(defaultFirstName);
    const [lastName, setLastName] = useState(defaultLastName);
    const [email, setEmail] = useState(defaultEmail);
    const [page, setPage] = useState(defaultPage);
    // const [acceptCheckbox, setAcceptCheckbox] = useState(true);
    const [sentTimer, setSentTimer] = useState(0);
    const [sending, setSending] = useState(false);

    const closeModal = () => {
        setModal(null);
    };

    const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setEmail(() => value);
    };

    const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFirstName(() => value);
    };

    const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setLastName(() => value);
    };

    // const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { checked } = e.target;
    //     setAcceptCheckbox(() => checked);
    // };

    const sendLink = async (
        data: UserSignUpData,
        newAccount: boolean = true
    ) => {
        const sendLink = await signInWithEmailLink(
            {
                ...data,
            },
            newAccount
        );
        if (sendLink) {
            setTimeout(() => {
                setPage('sent');
                setSentTimer(30);
                setSending(false);
            }, 1200);
        }
    };

    const onSubscribeSubmit = async () => {
        if (firstName && lastName && email) {
            const emailUsed = await getEmailUsed(email);
            if (!emailUsed) {
                await sendLink({
                    email,
                    firstName,
                    lastName,
                });
            } else {
                setSending(false);
                setPage('signin');
                setMessage(
                    <>
                        <p>
                            <b>That email is already used.</b>
                        </p>
                        <p>Sign in if it belongs to you.</p>
                    </>
                );
            }
        }
    };

    const onSignInSubmit = async () => {
        if (email) {
            const emailUsed = await getEmailUsed(email);
            if (!emailUsed) {
                setSending(false);
                setPage('subscribe');
                setMessage(
                    <>
                        <p>
                            <b>That email has no account.</b>
                        </p>
                        <p>Subscribe to create a new account.</p>
                    </>
                );
            } else {
                await sendLink(
                    {
                        email,
                    },
                    false
                );
            }
        }
    };

    useEffect(() => {
        if (sentTimer > 0) {
            const timeout = setTimeout(() => {
                setSentTimer(sentTimer - 1);
            }, 1000);
            return () => clearTimeout(timeout);
        }
    }, [sentTimer]);

    useEffect(() => {
        if (sending) {
            if (page === 'subscribe') {
                onSubscribeSubmit();
            } else if (page === 'signin') {
                onSignInSubmit();
            }
        }
    }, [sending, page]);

    return (
        <div className='SignUpModal modal'>
            <div className='overlay' onClick={() => closeModal()} />
            {(() => {
                if (page === 'sent') {
                    return (
                        <div className='SignUpContent SignUpSent'>
                            <div className='SentMessage'>
                                <div>
                                    <p>
                                        We're sending a magic link to your
                                        email.
                                    </p>
                                    <p>
                                        It may take a few minutes to arrive, and
                                        may accidentally be put in your Spam
                                        folder.
                                    </p>
                                    <p>
                                        If you haven't received one in the next
                                        few minutes,
                                    </p>
                                </div>
                                <button
                                    type='submit'
                                    onClick={() => {
                                        setSending(true);
                                    }}
                                    disabled={sending || !!sentTimer}
                                    className='CTA'
                                    style={
                                        theme?.colors?.primary
                                            ? {
                                                  backgroundColor:
                                                      theme.colors.primary,
                                              }
                                            : {}
                                    }
                                >
                                    {sending
                                        ? 'Sending...'
                                        : `Send again${
                                              sentTimer
                                                  ? ` in ${sentTimer}s`
                                                  : ''
                                          }`}
                                </button>
                            </div>
                            <button
                                className='Close'
                                onClick={closeModal}
                            ></button>
                        </div>
                    );
                } else {
                    return (
                        <form
                            className={`SignUpContent SignUpForm Page-${page}`}
                            onSubmit={(e: FormEvent) => {
                                e.preventDefault();
                                setSending(true);
                            }}
                        >
                            <div className='ContentWrapper'>
                                <div>
                                    {page === 'signin' && (
                                        <span className='Title'>
                                            Sign in with just your email to gain
                                            access to your saved favorites
                                        </span>
                                    )}
                                    {page === 'subscribe' && (
                                        <span className='Title'>
                                            {customMessage ||
                                                'Subscribe to come back to your favorites and for updates on new content you can enjoy.'}
                                        </span>
                                    )}
                                    <div className='ChangePage horizontal'>
                                        {page === 'subscribe' ? (
                                            <p
                                                className='link'
                                                onClick={() =>
                                                    setPage(() => 'signin')
                                                }
                                            >
                                                Already subscribed? Get a sign
                                                in link
                                            </p>
                                        ) : (
                                            <p
                                                className='link'
                                                onClick={() =>
                                                    setPage(() => 'subscribe')
                                                }
                                            >
                                                Back to sign up
                                            </p>
                                        )}
                                    </div>
                                    <div className='LinkNote horizontal'>
                                        <span role='img' aria-label='sparkles'>
                                            ✨
                                        </span>
                                        We email you a magic link for
                                        password-free sign in
                                    </div>
                                </div>
                                <div>
                                    {page === 'subscribe' ? (
                                        <>
                                            <input
                                                type='text'
                                                placeholder='First Name'
                                                value={
                                                    firstName ? firstName : ''
                                                }
                                                onChange={(e) =>
                                                    onFirstNameChange(e)
                                                }
                                                required
                                                autoFocus={!isMobile}
                                            />
                                            <input
                                                type='text'
                                                placeholder='Last Name'
                                                value={lastName ? lastName : ''}
                                                onChange={(e) =>
                                                    onLastNameChange(e)
                                                }
                                                required
                                            />
                                            <input
                                                type='email'
                                                placeholder='Email'
                                                value={email ? email : ''}
                                                onChange={(e) =>
                                                    onEmailChange(e)
                                                }
                                                required
                                            />
                                        </>
                                    ) : (
                                        <input
                                            type='email'
                                            placeholder='Email'
                                            value={email ? email : ''}
                                            onChange={(e) => onEmailChange(e)}
                                            required
                                            autoFocus={!isMobile}
                                        />
                                    )}
                                    {/* {page === 'subscribe' && (
                                        <div className='SubscribeCheckboxWrapper'>
                                            <input
                                                id='subscribeCheckbox'
                                                type='checkbox'
                                                checked={acceptCheckbox}
                                                onChange={(e) => onCheckboxChange(e)}
                                            />
                                            <label htmlFor='subscribeCheckbox'>
                                                I'd like to know when there's new content on
                                                WonderMapper
                                            </label>
                                        </div>
                                    )} */}
                                    <button
                                        type='submit'
                                        disabled={sending}
                                        className='CTA'
                                        style={
                                            theme?.colors?.primary
                                                ? {
                                                      backgroundColor:
                                                          theme.colors.primary,
                                                  }
                                                : {}
                                        }
                                    >
                                        {sending
                                            ? 'Sending...'
                                            : page === 'signin'
                                            ? 'Sign in'
                                            : 'Subscribe'}
                                    </button>
                                    <div className='ChangePage not-horizontal'>
                                        {page === 'subscribe' ? (
                                            <p
                                                className='link'
                                                onClick={() =>
                                                    setPage(() => 'signin')
                                                }
                                            >
                                                Already subscribed? Get a sign
                                                in link
                                            </p>
                                        ) : (
                                            <p
                                                className='link'
                                                onClick={() =>
                                                    setPage(() => 'subscribe')
                                                }
                                            >
                                                Back to sign up
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='LinkNote not-horizontal'>
                                <span role='img' aria-label='sparkles'>
                                    ✨
                                </span>
                                We email you a magic link for password-free sign
                                in
                            </div>
                            <button
                                className='Close'
                                onClick={closeModal}
                            ></button>
                        </form>
                    );
                }
            })()}
        </div>
    );
};
