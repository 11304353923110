import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';

import { EmailModalOverlay } from '../components/EmailModalOverlay';
import { ModalContext } from './ModalContext';

export type EmailContextType = {
    emailScrollCardActive: boolean;
    scriptLoaded: boolean;

    loadEmailForm: (formId: string, cb?: any) => void;
    setEmailModalActive: Dispatch<SetStateAction<boolean>>;
    setEmailScrollCardActive: Dispatch<SetStateAction<boolean>>;
};

const emailDefaultState = {
    emailModalActive: false,
    emailScrollCardActive: true,
    scriptLoaded: false,

    loadEmailForm: () => {},
    setEmailModalActive: () => {},
    setEmailScrollCardActive: () => {},
};

export const EmailContext = createContext<EmailContextType>(emailDefaultState);
export const EmailConsumer = EmailContext.Consumer;

interface EmailContextWrapperProps {
    children: any;
}
export const EmailContextWrapper: FunctionComponent<EmailContextWrapperProps> = (
    props: EmailContextWrapperProps
) => {
    const { setModal } = useContext(ModalContext);

    const {
        emailScrollCardActive: defaultEmailScrollCardActive,
        scriptLoaded: defaultScriptLoaded,
    } = emailDefaultState;

    const [emailScrollCardActive, setEmailScrollCardActive] = useState(
        defaultEmailScrollCardActive
    );
    const [scriptLoaded, setScriptLoaded] = useState(defaultScriptLoaded);
    const loadEmailForm = (formId: string, cb: any) => {
        if (window.mootrack) {
            window.mootrack('loadForm', formId);
            const targetNode = document.querySelector(
                `.EmailModalWrapper div[data-mooform-id]`
            );
            const config = { attributes: true, childList: true, subtree: true };
            if (targetNode) {
                const observer = new MutationObserver(
                    (mutationsList, observer) => {
                        for (const mutation of mutationsList) {
                            const { addedNodes } = mutation;
                            if (
                                addedNodes &&
                                addedNodes.length > 0 &&
                                addedNodes[0]
                            ) {
                                const node = addedNodes[0] as HTMLDivElement;
                                if (
                                    node.tagName === 'DIV' &&
                                    node.id.indexOf('msf_') === 0
                                ) {
                                    const form = node.querySelector('form');
                                    if (form) {
                                        observer.disconnect();
                                        cb && cb(form);
                                    }
                                }
                            }
                        }
                    }
                );
                observer.observe(targetNode, config);
            }
        }
    };

    const setEmailModalActive: EmailContextType['setEmailModalActive'] = () => {
        setModal(<EmailModalOverlay />);
    };

    const store = {
        emailScrollCardActive,
        scriptLoaded,

        loadEmailForm,
        setEmailModalActive,
        setEmailScrollCardActive,
    };

    useEffect(() => {
        if (window.mootrack) return setScriptLoaded(true);

        const script = document.createElement('script');
        script.src =
            'https://cdn.stat-track.com/statics/moosend-tracking.min.js';
        script.async = true;
        script.onload = () => setScriptLoaded(true);
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <EmailContext.Provider value={store}>
            {props.children}
        </EmailContext.Provider>
    );
};
