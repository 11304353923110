import React, {
    ReactNode,
    createContext,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

export type MessageContextType = {
    // State
    message: {
        active: boolean;
        content: ReactNode | string | undefined;
    };

    // Actions
    hideMessage: () => void;
    setMessage: (message?: ReactNode | string | undefined) => void;
};

const messageDefaultState = {
    message: {
        active: false,
        content: undefined,
    },

    hideMessage: () => {},
    setMessage: () => {},
};
export const MessageContext = createContext<MessageContextType>(
    messageDefaultState
);
export const MessageConsumer = MessageContext.Consumer;

interface MessageContextWrapperProps {
    children: any;
}
export const MessageContextWrapper: FunctionComponent<MessageContextWrapperProps> = (
    props: MessageContextWrapperProps
) => {
    const { message: defaultMessage } = messageDefaultState;

    // const [ message, setMessageState ] = useState<MessageContextType['message']>(defaultMessage);
    const [messageActive, setMessageActive] = useState<
        MessageContextType['message']['active']
    >(defaultMessage.active);
    const [messageContent, setMessageContent] = useState<
        MessageContextType['message']['content']
    >(defaultMessage.content);

    const lastMessageTimeout = useRef<number | null>(null);

    const hideMessage = useCallback(() => {
        setMessageActive(false);
        lastMessageTimeout.current = null;
    }, [setMessageActive]);

    const setMessage: MessageContextType['setMessage'] = useCallback(
        (newMessage) => {
            if (!newMessage) {
                setMessageActive(false);
            } else {
                setMessageContent(newMessage);
                setMessageActive(true);
            }
        },
        [setMessageActive, setMessageContent]
    );

    useEffect(() => {
        // if (message.active) {
        if (messageActive) {
            lastMessageTimeout.current = window.setTimeout(hideMessage, 5000);
        }
        return () => {
            if (lastMessageTimeout.current) {
                clearTimeout(lastMessageTimeout.current);
            }
        };
    }, [lastMessageTimeout, messageActive, hideMessage]);

    const store = {
        message: {
            content: messageContent,
            active: messageActive,
        },
        hideMessage,
        setMessage,
    };

    return (
        <MessageContext.Provider value={store}>
            {props.children}
        </MessageContext.Provider>
    );
};
