import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';

import { App } from './components/App';

import { Store } from './contexts/index';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path='/:journeySlug'>
                    <Store>
                        <App />
                    </Store>
                </Route>
                <Redirect to={`/wonder-california${window.location.search}`} />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);
