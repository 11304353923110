import { intersectionBy, isEqual } from 'lodash';
import React, {
    createContext,
    Dispatch,
    FunctionComponent,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useFullScreenHandle, FullScreenHandle } from 'react-full-screen';

import { AuthContext } from './AuthContext';
import { DataContext } from '../contexts/DataContext';
import { MessageContext } from './MessageContext';

import '../utils/zoid';

import { Location } from '../types/Location';
import { isWebView } from '../utils/utils';

type FilterOptions = {
    [key: string]: any;
};

export type ExperienceContextType = {
    // State
    filteredLocations: Location[] | null;
    filterOptions: FilterOptions;
    fullScreenHandler: FullScreenHandle | null;
    mouseActive: boolean;
    muted: boolean;
    topFrame: Window | null;
    xProps: any;

    // Actions
    setFilters: (newFilterOptions?: FilterOptions, setUrl?: boolean) => string;
    setMuted: Dispatch<SetStateAction<ExperienceContextType['muted']>>;
};

const experienceDefaultState = {
    filteredLocations: null,
    filterOptions: {},
    fullScreenHandler: null,
    mouseActive: true,
    muted: isWebView || false,
    topFrame: window.top === window.self ? null : window.top, // If not in iFrame, this is null.
    xProps: null,

    setFilters: () => '',
    setMuted: () => {},
};
export const ExperienceContext = createContext<ExperienceContextType>(
    experienceDefaultState
);
export const ExperienceConsumer = ExperienceContext.Consumer;

export const ExperienceContextWrapper: FunctionComponent = (props) => {
    const { user } = useContext(AuthContext);
    const {
        locations: unfilteredLocations,
        fetchFavorites,
        favorites,
        otherUserData,
    } = useContext(DataContext);
    const { setMessage } = useContext(MessageContext);
    const handler = useFullScreenHandle();
    const history = useHistory();
    const { search } = useLocation();
    const params = queryString.parse(search, { arrayFormat: 'bracket' });

    const {
        mouseActive: defaultMouseActive,
        muted: defaultMuted,
        topFrame,
        xProps: defaultXProps,
    } = experienceDefaultState;

    const [filterOptions, setFilterOptions] = useState<
        ExperienceContextType['filterOptions']
    >({});
    const [muted, setMuted] = useState<ExperienceContextType['muted']>(
        defaultMuted
    );
    const [xProps, setXProps] = useState<any>(window.xprops || defaultXProps); // iframe props from zoid
    const [mouseActive, setMouseActive] = useState<
        ExperienceContextType['mouseActive']
    >(defaultMouseActive);

    // const lastSearch = useRef<string | null>(null);
    const lastFilterOptions = useRef<FilterOptions | null>(null);
    const mouseActiveTimeout = useRef<number | null>(null);

    const setFilters: ExperienceContextType['setFilters'] = (
        newFilterOptions = {},
        setUrl = true
    ) => {
        const keyValues = Object.keys(newFilterOptions).map(
            (key) => `${key}:${newFilterOptions[key]}`
        );
        const newParams = { ...params };
        if (keyValues.length > 0) {
            newParams.filter = keyValues;
        } else {
            delete newParams.filter;
        }

        const newSearchString = queryString.stringify(newParams, {
            arrayFormat: 'bracket',
        });
        setUrl &&
            history.replace({
                search: newSearchString,
            });
        return newSearchString;
    };

    const filteredLocations = useMemo(() => {
        let filteredLocations = [...(unfilteredLocations || [])];

        if (Object.keys(filterOptions).length === 0) {
            return filteredLocations;
        }

        if (filterOptions.favorites) {
            try {
                // Get the target user's favorites.
                const myFavorites = filterOptions.favorites === user?.uid;
                const thisFavorites =
                    favorites[myFavorites ? 'my' : filterOptions.favorites] ||
                    [];
                filteredLocations = intersectionBy(
                    filteredLocations,
                    thisFavorites.map((ea) => ({ id: ea })),
                    'id'
                );
            } catch (e) {
                filteredLocations = [];
            }
        }

        return filteredLocations;
    }, [unfilteredLocations, favorites, filterOptions, user, setMessage]);

    useEffect(() => {
        if (params.filter) {
            if (!Array.isArray(params.filter) || params.filter.length === 0) {
                return;
            }
            const keyValues = (params.filter as string[]).map((ea) =>
                ea.split(':')
            );
            const filterOptions = keyValues.reduce((obj, pair) => {
                obj[pair[0]] = pair[1];
                return obj;
            }, {} as FilterOptions);

            if (isEqual(lastFilterOptions.current, filterOptions)) return;
            lastFilterOptions.current = filterOptions;
            setFilterOptions(filterOptions);
        } else {
            if (isEqual(lastFilterOptions.current, {})) return;
            lastFilterOptions.current = {};
            setFilterOptions({});
        }
    }, [lastFilterOptions, filterOptions, params]);

    // Kick off any fetches after filterOptions update.
    useEffect(() => {
        function execFetches() {
            if (
                filterOptions.favorites &&
                !otherUserData[filterOptions.favorites]
            ) {
                fetchFavorites(filterOptions.favorites);
            }
        }

        if (user) {
            console.log('Fetching filter data.');
            execFetches();
        }
    }, [user, lastFilterOptions, filterOptions]);

    useEffect(() => {
        // Once the user is resolved, then show the message.
        if (user && filterOptions.favorites) {
            const myFavorites = filterOptions.favorites === user.uid;
            setMessage(`Viewing ${myFavorites ? 'my' : 'shared'} favorites ❤️`);
        }
    }, [user, filterOptions]);

    useEffect(() => {
        window.xprops &&
            window.xprops.onProps((props: any) => {
                setXProps(props);
            });
    }, []);

    // Mouse activity handler
    useEffect(() => {
        const timeoutDuration = 1600;
        const setMouseInactive = () => {
            setMouseActive(false);
        };

        mouseActiveTimeout.current = window.setTimeout(
            setMouseInactive,
            timeoutDuration
        );

        const handler = () => {
            requestAnimationFrame(() => {
                if (mouseActiveTimeout.current)
                    clearTimeout(mouseActiveTimeout.current);
                mouseActiveTimeout.current = window.setTimeout(
                    setMouseInactive,
                    timeoutDuration
                );
                if (!mouseActive) setMouseActive(true);
            });
        };

        document.addEventListener('mousemove', handler);
        document.addEventListener('touchmove', handler);

        return () => {
            if (mouseActiveTimeout.current)
                clearTimeout(mouseActiveTimeout.current);
            document.removeEventListener('mousemove', handler);
            document.removeEventListener('touchmove', handler);
        };
    }, [mouseActive, mouseActiveTimeout]);

    // Fullscreen handler wrap in try...catch
    (() => {
        const ogFunc = handler.enter;
        handler.enter = (withErrorMsg = true) => {
            try {
                ogFunc();
            } catch (e) {
                if (withErrorMsg)
                    setMessage(
                        'Your device does not support fullscreen websites.'
                    );
            }
        };
    })();

    const store = {
        filteredLocations,
        filterOptions,
        fullScreenHandler: handler,
        mouseActive,
        muted,
        topFrame,
        xProps,

        setFilters,
        setMuted,
    };

    return (
        <ExperienceContext.Provider value={store}>
            {props.children}
        </ExperienceContext.Provider>
    );
};
