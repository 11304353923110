import * as zoid from 'zoid/dist/zoid.frameworks.js';

export const zoidComponent = zoid.create({
    tag: 'wondermap',
    url: 'https://127.0.0.1:3000',
    dimensions: {
        width: '100%',
        height: '80vh',
    },
    attributes: {
        iframe: {
            allowfullscreen: true,
        },
    },
    props: {
        wmPath: {
            type: 'string',
        },
    },
});
