// this is the store component that will wrap the entire app -- allowing providers to pass values down to
// consumers through to all components

import React, { FunctionComponent } from 'react';

import { AuthContextWrapper } from './AuthContext';
import { DataContextWrapper } from './DataContext';
import { ExperienceContextWrapper } from './ExperienceContext';
import { ExploreContextWrapper } from './ExploreContext';
import { MessageContextWrapper } from './MessageContext';
import { ModalContextWrapper } from './ModalContext';
import { ThemeContextWrapper } from './ThemeContext';
import { EmailContextWrapper } from './EmailContext';

interface StoreProps {
    children: any;
}
export const Store: FunctionComponent<StoreProps> = (props: StoreProps) => {
    return (
        <ModalContextWrapper>
            <EmailContextWrapper>
                <MessageContextWrapper>
                    <AuthContextWrapper>
                        <DataContextWrapper>
                            <ExperienceContextWrapper>
                                <ExploreContextWrapper>
                                    <ThemeContextWrapper>
                                        {props.children}
                                    </ThemeContextWrapper>
                                </ExploreContextWrapper>
                            </ExperienceContextWrapper>
                        </DataContextWrapper>
                    </AuthContextWrapper>
                </MessageContextWrapper>
            </EmailContextWrapper>
        </ModalContextWrapper>
    );
};
