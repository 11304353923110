import classNames from 'classnames';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { EmailContext } from '../contexts/EmailContext';
import { ModalContext } from '../contexts/ModalContext';
import { useEventListener } from '../hooks/useEventListener';

export const EmailModalOverlay: FC = () => {
    const { loadEmailForm } = useContext(EmailContext);
    const { setModal } = useContext(ModalContext);

    const [formLoaded, setFormLoaded] = useState<boolean>(false);

    const formElement = useRef<HTMLFormElement | null>(null);

    useEffect(
        () =>
            loadEmailForm(
                'aa2f7654a49f44698bbbb32f3dad53d5',
                (formNode: HTMLFormElement) => {
                    formElement.current = formNode;
                    setFormLoaded(true);
                }
            ),
        []
    );

    useEventListener(
        'click',
        () => {
            if (formElement.current) {
                const form = formElement.current;
                const data = new FormData(form);
                console.log(data);
            }
        },
        (formElement.current
            ? formElement.current.querySelector('button')
            : null) as HTMLElement
    );

    const closeModal = () => {
        setModal(null);
    };

    return (
        <div
            className={classNames('EmailOverlayWrapper', {
                visible: formLoaded,
            })}
        >
            <div className='overlay' onClick={closeModal}></div>
            <div className='EmailModalWrapper'>
                <button className='Close' onClick={closeModal}></button>
                <div data-mooform-id='aa2f7654-a49f-4469-8bbb-b32f3dad53d5'></div>
            </div>
        </div>
    );
};
